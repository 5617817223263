import React, { Component } from "react"
import { Document, Page } from "react-pdf"
import "./styles.scss"

export default class PDFWidget extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    }

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document
        this.setState({
            numPages,
            pageNumber: 1,
        })
    }

    changePage = (offset) =>
        this.setState((prevState) => ({
            pageNumber: prevState.pageNumber + offset,
        }))

    previousPage = () => this.changePage(-1)

    nextPage = () => this.changePage(1)

    render() {
        const { numPages, pageNumber } = this.state

        return (
            <div className="md-widget_pdf">
                <Document file={this.props.url} onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
                <div className="pdf-nav">
                    <button type="button" disabled={pageNumber <= 1} onClick={this.previousPage}>
                        Vorige
                    </button>
                    <p>
                        Pagina {pageNumber || (numPages ? 1 : "--")} van {numPages || "--"}
                    </p>

                    <button type="button" disabled={pageNumber >= numPages} onClick={this.nextPage}>
                        Volgende
                    </button>
                </div>
            </div>
        )
    }
}
